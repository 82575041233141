$(function () {
  'use strict';
  $('[placeholder]')
    .focus(function () {
      $(this).attr('data-text', $(this).attr('placeholder'));
      $(this).attr('placeholder', '');
    })
    .blur(function () {
      $(this).attr('placeholder', $(this).attr('data-text'));
    });

  /* Crousel */

  // $('#recipeCarousel-ramadan').carousel({
  //   interval: 3000,
  // });
  // $('#recipeCarousel').carousel({
  //   interval: 2000,
  // });

  $('.carousel .carousel-item').each(function () {
    var minPerSlide = 3;
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));

    for (var i = 0; i < minPerSlide; i++) {
      next = next.next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }

      next.children(':first-child').clone().appendTo($(this));
    }
  });

  /* Navbar  */
  $('.nav .nav-link').on('click', function () {
    $('.nav').find('.active').removeClass('active');
    $(this).addClass('active');
  });

  /* Scroll */
  $(document).on('scroll', function () {
    if ($(document).scrollTop() > 86) {
      $('#banner').addClass('shrink');
    } else {
      $('#banner').removeClass('shrink');
    }
  });

  /* Modal */
  $(document).ready(function () {
    // Gets the video src from the data-src on each button

    var $videoSrc;
    $('.video-btnn').click(function () {
      $videoSrc = $(this).data('src');
    });

    // when the modal is opened autoplay it
    $('#myModal').on('shown.bs.modal', function (e) {
      // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
      $('#video').attr(
        'src',
        $videoSrc + '?autoplay=1&modestbranding=1&showinfo=0&controls=0'
      );
    });

    // stop playing the youtube video when I close the modal
    $('#myModal').on('hide.bs.modal', function (e) {
      // a poor man's stop video
      $('#video').attr('src', $videoSrc);
    });

    // document ready
  });
});

/* Shrink Website nav*/
const mq = window.matchMedia('(max-width: 991px)');
if (mq.matches) {
  $('#banner').addClass('shrink');
}

/* Read more */
function readMore(feature) {
  let dots = document.querySelector(
    `.featuremuslim[data-feature="${feature}"] .dots`
  );
  let moreText = document.querySelector(
    `.featuremuslim[data-feature="${feature}"] .more`
  );
  let btnText = document.querySelector(
    `.featuremuslim[data-feature="${feature}"] .readBtn`
  );

  if (dots.style.display === 'none') {
    dots.style.display = 'inline';
    btnText.textContent = 'Read more';
    moreText.style.display = 'none';
  } else {
    dots.style.display = 'none';
    btnText.textContent = 'Read less';
    moreText.style.display = 'inline';
  }
}

const blogs = document.getElementById('blogs-row');
const URL = 'https://muslimdo.com/blog/wp-json/wp/v2/posts?per_page=3';

async function getBlogs() {
  const response = await fetch(URL);
  const data = await response.json()
  getPostData(data)
}

function getPostData(data) {
  let posts = [];
  data.forEach(function (post) {
    let postLength = post.data.excerpt.rendered
    var count = 131
    var blogPost = postLength.slice(0, count) + (postLength.length > count ? `${ `<a class="readBtn" href="${post.data.link}"> ... More</a>` }` : "");;
    blogs.innerHTML += `
    <div class="col-12 col-lg-4">
      <div class="card border-0 bg-transparent">
        <a href="${post.data.link}"><img class="card-img-top shadow rounded" src="${post.data.fimg_url}" alt="Image"></a>
        <div class="card-body pt-5">
          <h5 class="h5 font-weight-medium">
            <a class="link-title readBtn" href="${post.data.link}">${post.data.title.rendered}</a>
          </h5>
          ${blogPost}
        </div>
      </div>
    </div>
    `
  })
}

getBlogs()
